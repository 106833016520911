import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
const leadInitialState = {
  leadId: '',
  userRequestId: '',
  valoreCasa: '',
  importoMutuo: '',
  eta: '',
  tasso: '',
  durata: '',
  finalita: '',
  ricerca: '',
  comune: '',
  regione: '',
  provincia: '',
  cap: '',
  idoneita: '',
  consulenza: '',
  fattibilita: '',
  intestatari: '',
  reddito: '',
  redditoOpen: '',
  contratto: '',
  product: {},
  product_other: {},
  user: {},
  provenienza: [],
  variationVWO: '',
  landedIn: '',
  passedThrough: [],
};
const createLeadSlice = set => ({
  ...leadInitialState,
  resetState: () => set(leadInitialState),
  setUserRequestId: userRequestId => set(() => ({ userRequestId })),
  setLeadId: leadId => set(() => ({ leadId })),
  setValoreCasa: valoreCasa => set(() => ({ valoreCasa })),
  setImportoMutuo: importoMutuo => set(() => ({ importoMutuo })),
  setEta: eta => set(() => ({ eta })),
  setTasso: tasso => set(() => ({ tasso })),
  setDurata: durata => set(() => ({ durata })),
  setFinalita: finalita => set(() => ({ finalita })),
  setRicerca: ricerca => set(() => ({ ricerca })),
  setComune: comune => set(() => ({ comune })),
  setCap: cap => set(() => ({ cap })),
  setRegione: regione => set(() => ({ regione })),
  setProvincia: provincia => set(() => ({ provincia })),
  setIdoneita: idoneita => set(() => ({ idoneita })),
  setConsulenza: consulenza => set(() => ({ consulenza })),
  setFattibilita: fattibilita => set(() => ({ fattibilita })),
  setIntestatari: intestatari => set(() => ({ intestatari })),
  setReddito: reddito => set(() => ({ reddito })),
  setUser: user => set(state => ({ user: { ...state.user, ...user } })),
  setProduct: product => set(state => ({ product: { ...state.product, ...product } })),
  resetProduct: () => set(() => ({ product: {} })),
  setProductOther: product_other => set(state => ({ product_other: { ...state.product_other, ...product_other } })),
  resetProductOther: () => set(() => ({ product_other: {} })),
  setRedditoOpen: redditoOpen => set(() => ({ redditoOpen })),
  setContratto: contratto => set(() => ({ contratto })),
  setProvenienza: provenienza => set(() => ({ provenienza })),
  setLandedIn: landedIn => set(() => ({ landedIn })),
  setVariationVWO: variationVWO => set(() => ({ variationVWO })),
  setPassedThrough: url => set(state => ({ passedThrough: [...state.passedThrough, url] })),
  answers: {},
  updateAnswer: (step, value, varName) =>
    set(state => {
      state.answers[step] = value;
    }),
});
export const useStore = create(
  persist(
    immer((...args) => ({ ...createLeadSlice(...args) })),
    {
      name: 'tassomutuo-store',
      version: 0,
      storage: createJSONStorage(() => localStorage),
    },
  ),
);
